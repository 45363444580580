import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Upload,
  notification,
  Row,
  Col,
} from "antd"; // Import additional Ant Design components
import axiosInstance from "../../utils/axios";

const FoodTable = ({ onDelete }) => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [editedFood, setEditedFood] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const [isImageModalVisible, setIsImageModalVisible] = useState(false); // Add state for image modal

  useEffect(() => {
    const fetchFoodItems = async () => {
      try {
        const response = await axiosInstance.get("/api/food/items");
        if (response.status === 200) {
          setData(response.data.foodItems);
        }
      } catch (error) {
        console.error("Error fetching food items:", error);
      }
    };

    fetchFoodItems();
  }, []);

  const showModal = (record) => {
    setEditedFood(record);
    setSelectedImages(record.images);
    form.setFieldsValue({
      name: record.name,
      category: record.category,
      ingredients: record.ingredients,
      description: record.description,
      extraToppings: record.extraToppings,
      foodType: record.foodType,
      price: record.price,
      readyTime: record.readyTime,
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedImages([]);
  };

  const handleUpdate = async (values) => {
    // const ingredientsArray = values.ingredients
    //   .split(",")
    //   .map((item) => item.trim());
    // const extraToppingsArray = values.extraToppings
    //   .split(",")
    //   .map((item) => item.trim());

    const formData = new FormData();
    // formData.append("name", values.name);
    // formData.append("category", values.category);
    // formData.append("foodType", values.foodType);
    // formData.append("price", values.price);
    // formData.append("readyTime", values.readyTime);
    // formData.append("ingredients", ingredientsArray.join(","));
    // formData.append("description", values.description);
    // formData.append("extraToppings", extraToppingsArray.join(","));

    selectedImages.forEach((file) => {
      formData.append("images", file); // Append images to formData
    });

    console.log("Form Data=", values);

    setLoading(true); // Set loading to true before the upload starts

    try {
      const response = await axiosInstance.put(
        `/api/food/update/${editedFood._id}`,
        formData, // Send the formData
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data
          },
        }
      );

      if (response.status === 200) {
        notification.success({ message: "Food item updated successfully!" }); // Show success notification
        window.location.reload(); // Reload the page after updating
      }
    } catch (error) {
      console.error("Error updating food item:", error);
      notification.error({
        message: "Failed to update food item. Please try again.",
      });
    } finally {
      setLoading(false); // Set loading to false after the upload completes
    }
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this food item?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axiosInstance.delete(`/api/food/delete/${id}`, {});
          onDelete(id); // Call the onDelete function passed from Dashboard
          window.location.reload(); // Reload the page after deletion
        } catch (error) {
          console.error("Error deleting food item:", error);
        }
      },
    });
  };

  const handleTogglePopularity = async (id) => {
    try {
      await axiosInstance.put(`/api/food/popular/${id}`, null, {});
      // Optionally, you can refresh the data or update the state here
      window.location.reload(); // Reload the page after updating popularity
    } catch (error) {
      console.error("Error updating food popularity:", error);
    }
  };

  const showImageModal = (images) => {
    setSelectedImages(images); // Set the selected images to display
    setIsImageModalVisible(true); // Show the image modal
  };

  const handleImageModalCancel = () => {
    setIsImageModalVisible(false); // Hide the image modal
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "images",
      render: (images) => (
        <div>
          <img
            src={images[0]} // Show only the first image
            alt="food"
            className="w-10 h-10 rounded-full cursor-pointer"
            onClick={() => showImageModal(images)} // Show image modal on click
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Ingredients", // Added ingredients column
      dataIndex: "ingredients",
    },
    {
      title: "Description", // Added description column
      dataIndex: "description",
    },
    {
      title: "Extra Toppings", // Added extra toppings column
      dataIndex: "extraToppings",
    },
    {
      title: "Food Type",
      dataIndex: "foodType",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (text) => `${text.toFixed(2)} birr`, // Format price
    },
    {
      title: "Ready Time", // Added ready time column
      dataIndex: "readyTime",
      render: (text) => `${text} min`, // Format ready time
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Row gutter={16}>
          {" "}
          {/* Use Row for better spacing */}
          <Col>
            <Button
              onClick={() => showModal(record)} // Call edit function
              type="default"
              className="hover:scale-110"
            >
              Edit
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => handleDelete(record._id)}
              type="danger"
              className="hover:scale-110 hover:text-red-600"
            >
              Delete
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => handleTogglePopularity(record._id)}
              type={record.popular ? "primary" : "default"}
              className="ml-2"
            >
              {record.popular ? "Popular" : "Unpopular"}
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="_id"
        pagination={{ responsive: true }}
      />{" "}
      {/* Enable responsive pagination */}
      <Modal
        title="Edit Food Item"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="min-w-max"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdate} // Call handleUpdate on form submission
        >
          {/* <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="ingredients" label="Ingredients">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input />
          </Form.Item>
          <Form.Item name="extraToppings" label="Extra Toppings">
            <Input />
          </Form.Item>
          <Form.Item name="foodType" label="Food Type">
            <Input />
          </Form.Item>
          <Form.Item name="price" label="Price" rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="readyTime"
            label="Ready Time"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item> */}
          <Form.Item label="Upload Images">
            <Upload
              beforeUpload={(file) => {
                setSelectedImages((prev) => [...prev, file]); // Add new images to the state
                return false; // Prevent automatic upload
              }}
              multiple
            >
              <Button>Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* New Modal for displaying images */}
      <Modal
        title="Uploaded Images"
        visible={isImageModalVisible}
        onCancel={handleImageModalCancel}
        footer={null}
      >
        <div className="flex flex-wrap">
          {selectedImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Uploaded ${index}`}
              className="w-20 h-20 m-2"
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default FoodTable;
