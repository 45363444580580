import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import axiosInstance from "../../utils/axios";
import { slideUp } from "../../components/Hero/Hero";
import torta from "../../assets/icons/birthday-cake.png";
import breads from "../../assets/icons/breads.png";
import cake from "../../assets/icons/cake.png";
import beverages from "../../assets/icons/cocktail.png";
import breakfast from "../../assets/icons/croissant.png";
import tart from "../../assets/icons/egg-tart.png";
import quiche from "../../assets/icons/quiche.png";
import lunch from "../../assets/icons/serving-dish.png";
import logo from "../../assets/fourth.png";

const categories = [
  { name: "Tarts, Quiches and Cakes", icons: [tart, quiche, cake] },
  { name: "Breakfast and Snacks", icon: breakfast },
  { name: "Lunch", icon: lunch },
  { name: "Breads", icon: breads },
  { name: "Torta", icon: torta },
  { name: "Beverages", icon: beverages },
];

const Menu = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (selectedCategory) {
      fetchMenuItems(selectedCategory);
    }
  }, [selectedCategory]);

  const fetchMenuItems = async (category) => {
    try {
      const response = await axiosInstance.get(
        `/api/food/items?category=${category}`
      );
      setMenuItems(response.data.foodItems);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedItem.images.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < selectedItem.images.length - 1 ? prevIndex + 1 : 0
    );
  };

  const imageVariants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 300 : -300,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 300 : -300,
        opacity: 0,
      };
    },
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <section className="bg-gradient-to-b from-gray-900 to-black min-h-screen text-white relative">
      {/* Dynamic Background Animation */}
      <div className="absolute inset-0 bg-gradient-to-br from-gray-600 to-blue-500 opacity-20"></div>{" "}
      {/* Removed animate-pulse */}
      <div className="container mx-auto py-16 px-4 relative z-10">
        <div className="flex justify-center mb-8">
          <img
            src={logo}
            alt="Logo"
            className="h-48 w-96 mt-28" // Increased size, border class removed
          />
        </div>
        <motion.h1
          variants={slideUp(0.5)}
          initial="hidden"
          whileInView="show"
          className="text-7xl text-center text-black font-vibes mb-12 tracking-wide text-shadow-lg"
        >
          Our Menu
        </motion.h1>

        {/* Logo Display */}

        {/* Category Selection */}
        <div className="flex flex-wrap justify-center gap-4 mb-16">
          {categories.map((category, index) => (
            <motion.button
              key={category.name}
              variants={slideUp(index * 0.1)}
              initial="hidden"
              whileInView="show"
              className={`flex flex-col items-center px-6 py-3 rounded-full transition-all duration-300 ${
                selectedCategory === category.name
                  ? "bg-white text-gray-900"
                  : "bg-gray-800 hover:bg-gray-700 hover:animate-pulse" // Added pulse on hover
              }`}
              onClick={() => setSelectedCategory(category.name)}
            >
              <img
                src={category.icon || category.icons[0]}
                alt={category.name}
                className="h-12 w-12 mb-2"
              />
              <span className="text-lg font-medium">{category.name}</span>
            </motion.button>
          ))}
        </div>

        {/* Menu Items Grid */}
        {selectedCategory && (
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            initial="hidden"
            whileInView="show"
          >
            {menuItems.map((item) => (
              <motion.div
                key={item._id}
                whileHover={{ scale: 1.05 }}
                className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 hover:animate-pulse" // Added pulse on hover
              >
                <img
                  src={item.images[0]}
                  alt={item.name}
                  className="w-full h-64 object-cover transition-transform duration-300 hover:scale-105"
                />
                <div className="p-6">
                  <h3 className="text-3xl font-ephesis mb-2">{item.name}</h3>
                  <p className="text-gray-400 mb-4 line-clamp-2">
                    {item.description}
                  </p>
                  <div className="flex justify-between items-center">
                    <span className="text-2xl font-semibold">
                      {item.price.toFixed(2)} birr
                    </span>
                    <button
                      onClick={() => setSelectedItem(item)}
                      className="bg-white text-gray-900 px-4 py-2 rounded-full font-medium hover:bg-gray-200 transition-colors duration-300"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}

        {/* Selected Item Modal */}
        <AnimatePresence>
          {selectedItem && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
              onClick={() => setSelectedItem(null)} // Close modal on backdrop click
            >
              <motion.div
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9 }}
                className="bg-gray-800 rounded-lg max-w-3xl w-full overflow-hidden"
                onClick={(e) => e.stopPropagation()} // Prevent click from closing modal when clicking inside
              >
                {/* Modal content */}
                <div className="relative h-64 overflow-hidden">
                  <AnimatePresence initial={false} custom={currentImageIndex}>
                    <motion.img
                      key={currentImageIndex}
                      src={selectedItem.images[currentImageIndex]}
                      alt={`${selectedItem.name} - Image ${
                        currentImageIndex + 1
                      }`}
                      custom={currentImageIndex}
                      variants={imageVariants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                      }}
                      drag="x"
                      dragConstraints={{ left: 0, right: 0 }}
                      dragElastic={1}
                      onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                          handleNextImage();
                        } else if (swipe > swipeConfidenceThreshold) {
                          handlePrevImage();
                        }
                      }}
                      className="w-full h-64 object-cover absolute top-0 left-0"
                    />
                  </AnimatePresence>
                  <button
                    onClick={() => setSelectedItem(null)} // Ensure this function is called
                    className="absolute hover:scale-110 cursor-pointer top-4 right-4 text-white bg-gray-900 rounded-full p-2 z-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>

                  {/* Image navigation buttons */}
                  <button
                    onClick={handlePrevImage}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-gray-900 rounded-full p-2 opacity-75 hover:opacity-100 transition-opacity duration-300 z-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={handleNextImage}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-gray-900 rounded-full p-2 opacity-75 hover:opacity-100 transition-opacity duration-300 z-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6">
                  <h2 className="text-4xl font-ephesis mb-4">
                    {selectedItem.name}
                  </h2>
                  <p className="text-gray-400 mb-4">
                    {selectedItem.description}
                  </p>
                  <p className="text-2xl font-semibold mb-4">
                    {selectedItem.price.toFixed(2)} birr
                  </p>
                  {/* Add more details here (ingredients, allergens, etc.) */}
                  {/* <button
                    onClick={() => handleAddToCart(selectedItem)}
                    className="w-full bg-white text-gray-900 py-3 rounded-full font-bold hover:bg-gray-200 transition-colors duration-300"
                  >
                    Add to Cart
                  </button> */}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default Menu;
