import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  IoLocationOutline,
  IoCallOutline,
  IoMailOutline,
} from "react-icons/io5";
// Add these imports
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
    // Reset form after submission
    setFormData({ name: "", email: "", message: "" });
  };

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  // Updated socialLinks array to include usernames
  const socialLinks = [
    {
      icon: FaFacebookF,
      url: "https://www.facebook.com/profile.php?id=61550514615213&mibextid=ZbWKwL",
      name: "Facebook",
      username: "@Chez Jean Pierre Patisseries",
    },
    {
      icon: FaInstagram,
      url: "https://www.instagram.com/chezjeanpierreparisserie/profilecard/?igsh=YjdmaDdpc3dwMmF0",
      name: "Instagram",
      username: "@chezjeanpierreparisserir",
    },
    {
      icon: FaTiktok,
      url: "https://www.tiktok.com/@chezjp?is_from_webapp=1&sender_device=pc",
      name: "LinkedIn",
      username: "@Chez Jean Pierre Patisseries",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black text-white min-h-screen py-20">
      <div className="container mx-auto px-6">
        <motion.h1
          className="text-5xl font-bold mb-12 mt-28 text-center"
          variants={fadeIn}
          initial="initial"
          animate="animate"
        >
          Contact Us
        </motion.h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
          {/* Contact Form */}
          <motion.div
            variants={fadeIn}
            initial="initial"
            animate="animate"
            className="bg-gray-800 p-8 rounded-lg shadow-lg"
          >
            <h2 className="text-3xl font-semibold mb-6">Get in Touch</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block mb-2 text-gray-400">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-gray-400">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                />
              </div>
              <div>
                <label htmlFor="message" className="block mb-2 text-gray-400">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className="w-full px-4 py-2 bg-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                ></textarea>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 w-full"
              >
                Send Message
              </motion.button>
            </form>
          </motion.div>

          {/* Contact Information */}
          <motion.div
            variants={fadeIn}
            initial="initial"
            animate="animate"
            className="space-y-8 bg-gray-800 p-8 rounded-lg shadow-lg"
          >
            <h2 className="text-3xl font-semibold mb-6">Our Information</h2>
            <div className="flex items-center space-x-4">
              <a
                href="https://maps.app.goo.gl/7FSAb2f1PMEsf1iS7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLocationOutline className="text-3xl hover:scale-125 duration-300 text-gray-400" />
              </a>
              <div>
                <h3 className="text-xl font-medium">Address</h3>
                <p className="text-gray-300 hover:scale-110 duration-300">
                  <a
                    href="https://maps.app.goo.gl/7FSAb2f1PMEsf1iS7"
                    className="hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Guinea Conakry St, Kazanchis, Addis Ababa
                  </a>
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              {" "}
              <a href="tel:+251989091989" className="hover:underline">
                <IoCallOutline className="text-3xl text-gray-400 hover:scale-125 duration-300" />
              </a>
              <div>
                <h3 className="text-xl font-medium">Phone</h3>
                <p className="text-gray-300 hover:scale-110 duration-300">
                  <a href="tel:+251967444777" className="hover:underline">
                    +251 967 444777 <br /> +251 989 091989
                  </a>
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="mailto:francaisepatisserie22@gmail.com"
                className="hover:underline"
              >
                <IoMailOutline className="text-3xl text-gray-400 hover:scale-125 duration-300" />
              </a>
              <div>
                <h3 className="text-xl font-medium">Email</h3>
                <p className="text-gray-300 hover:scale-110 duration-300">
                  <a
                    href="mailto:francaisepatisserie22@gmail.com"
                    className="hover:underline"
                  >
                    francaisepatisserie22@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div className="mt-8">
              <h3 className="text-xl font-medium mb-4">Opening Hours</h3>
              <ul className="space-y-2 text-gray-300">
                <li>Monday - Sunday: 7:30 AM - 08:00 PM</li>
              </ul>
            </div>
          </motion.div>

          {/* Add this new section for social media links */}
          <motion.div
            variants={fadeIn}
            initial="initial"
            animate="animate"
            className="mb-12"
          >
            <h2 className="text-3xl font-semibold mb-6 text-center">
              Connect With Us
            </h2>
            <div className="flex flex-col gap-3 justify-center space-x-6">
              {socialLinks.map((social, index) => (
                <motion.a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center bg-gray-800 p-4 rounded-full transition duration-300"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <social.icon className="text-2xl transition-colors duration-300 hover:text-gray-600" />
                  <span className="sr-only">{social.name}</span>
                  {/* Display username */}
                  <span className="text-sm text-gray-400">
                    {social.username}
                  </span>
                </motion.a>
              ))}
            </div>
          </motion.div>

          {/* Existing map section */}
          <motion.div
            variants={fadeIn}
            initial="initial"
            animate="animate"
            className="w-full"
          >
            <h2 className="text-3xl font-semibold mb-6">Find Us</h2>
            <div className="w-full h-[450px] rounded-lg overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d534.3533161091393!2d38.769260604460364!3d9.012322077580912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b856e95eaf90f%3A0x435949c2214cdfc1!2sChez%20Jean%20Pierre%20patisserie!5e0!3m2!1sen!2set!4v1728070266371!5m2!1sen!2set"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Restaurant Location"
              ></iframe>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
