import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import interior_one from "../../assets/chefs/slide/jean_habt.jpg";
import interior_8 from "../../assets/chefs/slide/slider.jpg";
import interior_9 from "../../assets/chefs/slide/slider_2.jpg";
import interior_10 from "../../assets/chefs/slide/slider_4.jpg";
import interior_11 from "../../assets/chefs/slide/slider_three.jpg";
import slider from "../../assets/chefs/slide/slider_4.jpg";
import slider_one from "../../assets/chefs/slide/slider_6.jpg";
import slider_three from "../../assets/chefs/slide/slider_6.jpg";
import slider_two from "../../assets/chefs/slide/slider_9.jpg";
import slider_four from "../../assets/chefs/slide/slider_10.jpg";
import chef_one from "../../assets/chefs/main_chefs.jpg";
import chef_two from "../../assets/chefs/main_chefs_2.jpg";
import jean_pierre from "../../assets/chefs/jean_pierre.jpg";
import { Link } from "react-router-dom";

const About = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    interior_one,
    slider,
    slider_four,
    slider_one,
    slider_three,
    slider_two,
    interior_8,
    interior_9,
    interior_10,
    interior_11,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeInOut" },
    },
  };

  const fadeInImage = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { duration: 0.8, delay: 0.3, ease: "easeInOut" },
    },
  };

  const slideAnimation = {
    initial: { opacity: 0, x: 300 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -300 },
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black text-white min-h-screen py-10 sm:py-20">
      <div className="container mx-auto px-4 sm:px-6">
        {/* Hero Section */}
        <motion.div
          className="relative overflow-hidden mb-8 sm:mb-16 mt-16 sm:mt-28"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1, transition: { duration: 1, ease: "easeInOut" } }}
        >
          <AnimatePresence mode="wait">
            <motion.img
              key={currentImageIndex}
              src={images[currentImageIndex]}
              alt={`Restaurant Interior ${currentImageIndex + 1}`}
              className="w-full h-full sm:h-72 md:h-80 lg:h-100 object-contain rounded-lg shadow-lg"
              variants={slideAnimation}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
          <motion.h1
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl sm:text-4xl md:text-5xl font-bold text-white bg-black bg-opacity-60 px-4 sm:px-8 py-2 sm:py-4 rounded-lg text-center"
            variants={fadeIn}
            initial="initial"
            animate="animate"
          >
            About Our Pastry Shop
          </motion.h1>
        </motion.div>

        {/* Story Section */}
        <motion.section
          className="mb-8 sm:mb-16"
          variants={fadeIn}
          initial="initial"
          animate="animate"
        >
          <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-center text-yellow-500">
            Our Story
          </h2>
          <div className="flex flex-col items-center justify-between gap-8">
            <p className="text-gray-300 leading-relaxed text-base sm:text-lg w-full">
              Founded in April 2023, Chez Jean Pierre Patisserie brings a touch
              of French elegance to the heart of Addis Ababa, Kazanchis. The
              story began in January 2013, when Mr. Jean Pierre first visited
              Ethiopia as a tourist. Captivated by the beauty of the country and
              the warmth of its people, he returned frequently, building a
              special connection with Ethiopia over the years. Inspired by this
              bond, he envisioned sharing a piece of French culture with the
              local community. After years of planning and a passion for
              bringing authentic French pastries to Addis Ababa, Chez Jean
              Pierre Patisserie was born. Today, we proudly offer a delightful
              selection of French treats, crafted with love and the finest
              ingredients.
            </p>
            {/* Removed the image */}
          </div>
        </motion.section>

        {/* Meet Our Chefs Section */}
        <motion.section
          className="mb-8 sm:mb-16"
          variants={fadeIn}
          initial="initial"
          animate="animate"
        >
          <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-center text-yellow-500">
            Meet Our Chefs
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <motion.div
              className="p-4 sm:p-6 bg-gray-800 rounded-lg shadow-lg hover:bg-gray-700 transition"
              whileHover={{ scale: 1.05 }}
            >
              {/* <h3 className="text-xl sm:text-2xl font-medium mb-2 text-white">
                Chef John Doe
              </h3> */}
              <p className="text-gray-300 text-sm sm:text-base">
                Over the past year and a half, we have invited highly
                experienced pastry chefs from France every six months to train
                our local pastry team.
              </p>
              <img
                src={chef_one}
                alt="Chef John"
                className="w-full h-40 sm:h-48 object-cover mt-4 rounded-lg"
              />
            </motion.div>
            <motion.div
              className="p-4 sm:p-6 bg-gray-800 rounded-lg shadow-lg hover:bg-gray-700 transition"
              whileHover={{ scale: 1.05 }}
            >
              {/* <h3 className="text-xl sm:text-2xl font-medium mb-2 text-white">
                Chef Jane Smith
              </h3> */}
              <p className="text-gray-300 text-sm sm:text-base">
                Their expertise and guidance have been instrumental in enhancing
                the skills of our staff, ensuring that we maintain the highest
                standards of French pastry craftsmanship. This initiative has
                been a great success, allowing us to bring authentic French
                flavors to our customers.
              </p>
              <img
                src={chef_two}
                alt="Chef Jane"
                className="w-full h-40 sm:h-48 object-cover mt-4 rounded-lg"
              />
            </motion.div>
          </div>
        </motion.section>

        {/* Chef Jean Pierre Section */}
        <motion.section
          className="mb-8 sm:mb-16"
          variants={fadeIn}
          initial="initial"
          animate="animate"
        >
          <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-center text-yellow-500">
            Meet Chef Jean Pierre
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-between gap-8">
            <motion.img
              src={jean_pierre} // Replace with the actual image path for Chef Jean Pierre
              alt="Chef Jean Pierre"
              className="w-full h-full sm:h-72 md:h-80 object-contain rounded-lg shadow-lg"
              variants={fadeInImage}
            />
            <p className="text-gray-300 leading-relaxed text-base sm:text-lg md:w-1/2">
              Chef Jean Pierre, hailing from the culinary heart of France,
              brings a rich heritage of exquisite flavors and techniques to our
              kitchen. With over 25 years of experience, he specializes in
              classic French cuisine, blending traditional recipes with modern
              twists. His passion for food and dedication to quality ingredients
              ensure that every dish is a masterpiece, inviting guests to savor
              the essence of French gastronomy.
            </p>
          </div>
          <div className="text-center mt-4">
            <Link to="/chef-jean-pierre" className="text-yellow-500 underline">
              Learn more about Chef Jean Pierre
            </Link>
          </div>
        </motion.section>

        {/* Philosophy Section */}
        <motion.section
          className="mb-8 sm:mb-16"
          variants={fadeIn}
          initial="initial"
          animate="animate"
        >
          <h2 className="text-3xl sm:text-4xl font-semibold mb-4 text-center text-yellow-500">
            Our Philosophy
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-between gap-8">
            <motion.img
              src={interior_one}
              alt="Philosophy"
              className="w-full h-60 sm:h-72 md:h-80 object-cover rounded-lg shadow-lg"
              variants={fadeInImage}
            />
            <p className="text-gray-300 leading-relaxed text-base sm:text-lg md:w-1/2">
              We are committed to using a maximum of locally sourced ingredients
              to create and discover new, healthy pastry products. Alongside
              this, we aim to develop our own signature cakes, continually
              enhancing the quality of our offerings. Improving customer service
              is also a priority, as we strive to elevate the overall experience
              for everyone who visits Chez Jean Pierre Patisserie.{" "}
            </p>
          </div>
        </motion.section>
      </div>
    </div>
  );
};

export default About;
