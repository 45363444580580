import React, { useState } from "react";
import FoodTable from "./FoodTable";
import AddFood from "./AddFood"; // Import AddFood

const Dashboard = () => {
  const [foodData, setFoodData] = useState([]);

  const handleAdd = (newFood) => {
    setFoodData([...foodData, newFood]); // Add new food item to the state
  };

  const handleDelete = (id) => {
    setFoodData(foodData.filter((item) => item._id !== id)); // Remove deleted food item from state
  };

  return (
    <div className="bg-gray-100 min-h-screen p-4 ">
      <h1 className="text-2xl font-bold mb-4 text-center">Food Management</h1>
      <AddFood onAdd={handleAdd} />
      <div className="mt-8">
        <FoodTable onDelete={handleDelete} />{" "}
      </div>
      {/* Pass the handleDelete function */}
    </div>
  );
};

export default Dashboard;
