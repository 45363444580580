import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:8000",
  //First Menu API
  // baseURL: "https://api.chezjeanpierrefrenchpatisserie.com/",
  //Second Menu API
  baseURL: "https://api2.chezjeanpierrefrenchpatisserie.com/",
  withCredentials: true,
});

export default instance;
