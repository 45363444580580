import React from "react";
import logo from "../../assets/fourth.png";

import { motion } from "framer-motion";
import { IoLogInOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom"; // Add this import

const Footer = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLoginClick = () => {
    navigate("/login"); // Navigate to the login page
  };

  return (
    <motion.footer
      initial={{ opacity: 0, y: 20 }} // Start with opacity 0 and slightly below
      whileInView={{ opacity: 1, y: 0 }} // Fade in and move to original position
      transition={{ duration: 1 }}
      className="bg-gradient-to-t from-black to-transparent border-t-2 border-gray-600 text-gray-400 rounded-t-3xl font-witt py-8" // Updated gradient and padding
    >
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start md:items-center px-4">
        {" "}
        {/* Centered and spaced */}
        <div className="space-y-3 lg:max-w-[300px]">
          <img src={logo} alt="logo_image" className="w-36" />
          <p className="text-sm">
            {" "}
            {/* Adjusted text size */}
            Founded in April 2023, Chez Jean Pierre Patisserie brings a touch of
            French elegance to the heart of Addis Ababa, Kazanchis. We proudly
            offer a delightful selection of French treats, crafted with love and
            the finest ingredients.
          </p>
          <a
            href="mailto:francaisepatisserie22@gmail.com"
            className="inline-block mt-6 text-sm hover:underline hover:scale-110 duration-500"
          >
            {" "}
            {/* Added hover effect */}
            francaisepatisserie22@gmail.com
          </a>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6 justify-items-end mt-6 md:mt-0">
          {" "}
          {/* Adjusted spacing */}
          <div className="justify-self-end">
            <h1 className="text-xl font-semibold">Quick Links</h1>
            <ul className="space-y-3 mt-6">
              <li className="hover:translate-x-1 duration-300 hover:underline cursor-pointer">
                <a href="/">Home</a>
              </li>
              <li className="hover:translate-x-1 duration-300 hover:underline cursor-pointer">
                <a href="/about">About</a>
              </li>
              <li className="hover:translate-x-1 duration-300 hover:underline cursor-pointer">
                <a href="/contact">Contact</a>
              </li>
              <li className="hover:translate-x-1 duration-300 hover:underline cursor-pointer">
                <a href="/menu">Menu</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button
        onClick={handleLoginClick} // Add onClick handler
        className="h-[40px] w-[40px] grid place-items-center text-2xl hover:scale-125 rounded-full text-white bg-gray-600 hover:bg-gray-700 transition duration-200" // Added background color and transition
      >
        <IoLogInOutline />
      </button>
    </motion.footer>
  );
};

export default Footer;
