import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { slideUp } from "../Hero/Hero";
import axiosInstance from "../../utils/axios";

const Popular = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); // State for selected item
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State for current image index

  useEffect(() => {
    const fetchFoodItems = async () => {
      try {
        const response = await axiosInstance.get(`/api/food/items`);
        if (response.status === 200) {
          const popularItems = response.data.foodItems.filter(
            (item) => item.popular === true
          );
          setData(popularItems);
        }
      } catch (error) {
        console.error("Error fetching food items:", error);
      }
    };

    fetchFoodItems();
  }, []);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : selectedItem.images.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < selectedItem.images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <section>
      <div className="container py-24">
        <motion.h3
          variants={slideUp(0.5)}
          initial="hidden"
          whileInView="show"
          className="text-8xl text-center font-custom text-dark  font-bold uppercase py-8 "
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
        >
          Our Popular Dishes
        </motion.h3>
        <div className="text-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 place-items-center">
          {data.map((item) => (
            <div
              key={item._id}
              className="group space-y-3 text-center bg-gray-900 opacity-70 shadow-xl p-3 rounded-xl transition duration-300 hover:bg-gray-800"
            >
              <img
                src={item.images[0]}
                alt="item_images"
                className="w-44 mx-auto img-shadow group-hover:scale-x-110 group-hover:translate-y-[-50px] group-hover:translate-x-10 group-hover:rotate-[50deg] transition-all duration-400"
              />
              <div>
                <button
                  onClick={() => {
                    setSelectedItem(item); // Set selected item for modal
                    setCurrentImageIndex(0); // Reset image index when opening modal
                  }}
                  className="bg-gray-200 text-black px-2 border border-gray-700 hover:bg-gray-400 transition duration-300 rounded-xl shadow-lg transform hover:scale-105 group-hover:mb-3 opacity-0 group-hover:opacity-100"
                >
                  See Details
                </button>
                <p className="text-xl font-semibold">{item.name}</p>
                <p className="text-xl font-bold text-gray-300">
                  {item.price} birr
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Selected Item Modal */}
      <AnimatePresence>
        {selectedItem && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-gray-800 rounded-lg max-w-3xl w-full overflow-hidden"
            >
              <div className="relative h-64 overflow-visible">
                <img
                  src={selectedItem.images[currentImageIndex]}
                  alt={selectedItem.name}
                  className="w-full h-64 object-cover"
                />
                <button
                  onClick={() => setSelectedItem(null)}
                  className="absolute top-4 right-4 text-white bg-gray-900 rounded-full p-2 z-10"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <div className="flex justify-between">
                  <button
                    onClick={handlePrevImage}
                    className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-gray-900 rounded-full p-2 opacity-75 hover:opacity-100 transition-opacity duration-300 z-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={handleNextImage}
                    className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-gray-900 rounded-full p-2 opacity-75 hover:opacity-100 transition-opacity duration-300 z-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="p-6">
                <h2 className="text-4xl font-ephesis text-white mb-4">
                  {selectedItem.name}
                </h2>
                <p className="text-white mb-4">{selectedItem.description}</p>
                <p className="text-2xl text-white font-semibold mb-4">
                  {selectedItem.price.toFixed(2)} birr
                </p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Popular;
